<template>
  <div>
    <div style="width:100%;padding: 15px;height: 100vh;background-color: #edeff2;min-height: 850px;">
      <div style="background-color: #fff;height: 100%">
        <!-- 菜单 -->
        <a-layout  style="background-color: #fff;">
          <a-layout-sider
            style="background-color: #fff;min-width: 200px;max-width: 200px;
            border-right:1px solid #edeff2"
          >
            <a-menu
              v-model="selectId"
              mode="inline"
              style="height: 100%;border-right: 0"
              @select="menuSelect"
            >
              <a-menu-item key="1">
                <span>支付设置</span>
              </a-menu-item>
              <!-- <a-menu-item key="2">
                <span>其他设置</span>
              </a-menu-item> -->
            </a-menu>
          </a-layout-sider>
          <a-layout-content style="">
            <div style="padding-top:34px;box-sizing: border-box;padding-right:50px" v-if="selectId[0] === '1'">
              <a-form-model
                ref="addItem"
                :label-col="labelCol"
                :model="addForm"
                :rules="addRules"
                :wrapper-col="wrapperCol"  
              >
                <a-row :gutter="24" class="rowItem">
                  <a-col
                      :span="12"
                      style="padding-left:0"
                    >
                    <div style="display: flex;" class="Form">
                      <a-form-model-item label="商户号" prop="mchId" class="formModel">
                        <a-input v-model="addForm.mchId" placeholder="请输入" />
                      </a-form-model-item>          
                      <a @click="viewModal('商户号')">如何查看</a>
                    </div>
                  </a-col>
                  <a-col
                      :span="12"
                      class="aCol"
                       style="padding-left:0"
                    >
                    <div style="display: flex;" class="Form">
                      <a-form-model-item label="商户API证书序列号" prop="mchSerialNo" class="formModel">
                        <a-input v-model="addForm.mchSerialNo" placeholder="请输入" />
                      </a-form-model-item>          
                      <a @click="viewModal('商户API证书序列号')">如何查看</a>
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="24" class="rowItem">
                  <a-col
                      :span="12"
                       style="padding-left:0"
                    >
                    <div style="display: flex;" class="Form">
                      <!-- <a-form-model-item label="商户私钥文件" prop="name" class="formModel">
                        <a-input v-model="addForm.name" placeholder="请输入" />
                      </a-form-model-item>      -->
                      <a-form-model-item
                        label="商户私钥文件"
                        class="formModel2"
                        prop="privateKeyPath"
                      >
                      
                        <div>
                          <input
                            style="
                              z-index: 11;
                              cursor: pointer;
                              width: 120px;
                              height: 32px;
                              opacity: 0;
                              position: absolute;
                              top: 0;
                            "
                            type="file"
                            @change="addFile"
                            ref="pdfUrl"
                            class="fileAdd"
                            id="inputTest"
                             accept=".pem"
                          />
                        </div>
                        <!-- <div class="uploadVideo">
                            <a-icon type="upload" /> 上传文件
                        
                        </div> -->
                        <a-button  class="uploadVideo"><a-icon type="upload" /> 上传文件</a-button>
                        <a @click="viewModal('商户私钥文件')" style="position: static;margin-left:6%">如何查看</a>
                        <div v-if="this.addForm.privateKeyPath !== '' && this.loadImg === false">{{ addForm.privateKeyPath }}</div>
                        <div v-if="this.loadImg === true" style="color: red;">上传中...</div>
                      </a-form-model-item>     
                      
                    </div>
                  </a-col>
                  <a-col
                   style="padding-left:0"
                      :span="12"
                    >
                    <div style="display: flex;" class="Form">
                      <a-form-model-item label="APIv3密钥 " prop="apiV3Key" class="formModel">
                        <a-input v-model="addForm.apiV3Key" placeholder="请输入" />
                      </a-form-model-item>          
                      <a @click="viewModal('APIv3密钥')">如何查看</a>
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="24" class="rowItem">
                  <a-col
                      :span="12"
                       style="padding-left:0"
                    >
                    <div style="display: flex;" class="Form">
                      <a-form-model-item label="APPID" prop="appId" class="formModel">
                        <a-input v-model="addForm.appId" placeholder="请输入" />
                      </a-form-model-item>          
                      <a @click="viewModal('APPID')">如何查看</a>
                    </div>
                  </a-col>
                </a-row>
              </a-form-model>
            </div>
            <div style="width:100%;display: flex;justify-content: center;margin-top: 100px;" v-if="selectId[0] === '1'">
              <a-button type="primary" @click="submitForm" style="width:100px">
                保存
              </a-button> 
            </div>
          </a-layout-content> 
        </a-layout>
      </div>
    </div>

    <!-- 导入框 -->
    <a-modal
      :title="'如何查看'+title"
      :visible="importModal"
      :footer="null"
      @cancel="()=>{this.importModal = false}"
      :width="title === '商户号' || title === 'APPID' || title === '商户API证书序列号'? '90%':'40%'"
      style="min-height: 400px;"
    >
      <div v-if="title === '商户号'">
        <img src="../../../assets/图片2.png" style="width:100%;height: auto;">
      </div>
      <!-- text-indent: 2em; -->
      <p v-if="title === '商户私钥文件'" class="pbaiqoian">
        查看操作设置链接：<a @click="windowOpen('https://kf.qq.com/faq/161222NneAJf161222U7fARv.html')"> https://kf.qq.com/faq/161222NneAJf161222U7fARv.html</a> 
      </p>
      <div v-if="title === 'APPID'">
        <img src="../../../assets/APPID.png" style="width:100%;height: auto;">
      </div>
      <div v-if="title === '商户API证书序列号'">
        <p style="font-size:16px">
          商户API证书序列号查看指引（续生成证书后才能查看）
        </p>
        <br>
        <img src="../../../assets/商户API证书序列号.png" style="width:100%;height: auto;">
      </div>
      <p v-if="title === 'APIv3密钥'" class="pbaiqoian">
        APIv3密钥设置方法链接：<a  @click="windowOpen('https://kf.qq.com/faq/180830E36vyQ180830AZFZvu.html')">https://kf.qq.com/faq/180830E36vyQ180830AZFZvu.html</a> 
      </p>
    </a-modal>
  </div>


</template>

<script>
import {
  getYCompanyPayConfig,setCompanyConfig
} from "@/service/PaymentSettings";
import BackgroundImage from '../../../components/meet/BackgroundImage.vue'
import {update, update_CompanyPayFile,update_File} from "@/utils/update";
export default {
  components: { BackgroundImage },
  data() {
    return {
      importModal:false,
      loadImg:false,
      title:'',
      InitialData:{},
      selectId: ["1"],
      labelCol: {span: 10},
      wrapperCol: {span: 14},
      addForm:{privateKeyPath:''},
      addRules: {
        mchId: [{ required: true,message: '请输入', trigger: 'blur'}],
        mchSerialNo: [{ required: true,message: '请输入', trigger: 'blur'}],
        privateKeyPath: [{ required: true,message: '请选择', trigger: 'change'}],
        apiV3Key: [{ required: true,message: '请输入', trigger: 'blur'}],
        appId: [{ required: true,message: '请输入', trigger: 'blur'}],
        // sex: [{ required: true,message: '请选择', trigger: 'change'}],
        // phone: [{ required: true,message: '请输入11位手机号', trigger: 'blur',pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,}],
        // province: [{ required: true, message: '请选择', trigger: 'change' }],
        // enclosure: [{required: true, message: '请上传', trigger: 'blur'}],
        // headImg: [{required: true, message: '请上传', trigger: 'change'}],
        // hospital: [{ required: true, message: '请输入', trigger: 'change' }],
        // into: [{required: true, message: '请输入', trigger: 'blur'}],
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    // 列表数据
    async getList() {
      // this.spin = true;
      const response = await getYCompanyPayConfig()
      if(response.code === 200 && response.data){
        this.addForm = response.data
        this.InitialData = JSON.parse(JSON.stringify(response.data))
        // this.pagination.total = response.count;
      }else {
        // this.$message.warning(response.message);
      }
      // this.spin = false;
    },
    menuSelect (e) {
    //   this.selectId[0] = e.key;
    //   this.assignment();
    },
    // 上传文件
    async addFile(){
      this.loadImg = true
      let file = this.$refs['pdfUrl'].files[0];
      if (file !== undefined){
        // 限制文件
        if(file.name.substring(file.name.length-4,file.name.length) !== '.pem'){
          this.$message.error('请上传.pem文件')
          this.loadImg = false
          return
        }
        let newVar = await update_CompanyPayFile(file);
        document.getElementById('inputTest').value = null
        if(newVar.code === 200){
          this.addForm.privateKeyPath = newVar.data
          this.$message.success('文件上传成功')
        }else {
          this.$message.error('上传文件失败'+ newVar.message)
        }
      }
      this.loadImg = false
    },
    viewModal(title){
      this.importModal = true
      this.title = title
    },
    async submitForm(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
         if(JSON.stringify(this.InitialData) === JSON.stringify(this.addForm)){
          return
         }
         let data = {
            "mchId": this.addForm.mchId,//商户号
            "mchSerialNo": this.addForm.mchSerialNo,//商户API证书序列号
            "privateKeyPath": this.addForm.privateKeyPath,//商户私钥文件存放路径
            "apiV3Key": this.addForm.apiV3Key,//APIv3密钥 
            "appId": this.addForm.appId//APPID *
            }
            const response = await setCompanyConfig(data)
            if(response.code === 200){
              this.$message.success('保存成功')
              this.getList()
            }else {
              this.$message.warning(response.message);
            }
        }
      })
    },
    windowOpen(url){
      window.open(url)
    }
  }
}
</script>

<style scoped lang="scss">
  .rowItem{
    box-sizing: border-box;
    // margin-bottom: 25px;
    .Form{
    //   display: flex;
    //   align-items: center;
      .formModel{
        width:80%;
        margin-right:15px;
        position: relative;
      }
      .formModel2{
        width:80%;
        margin-right:15px;
        #aHtml{
          font-size:13px;
          // margin-top:9px;
          // position: absolute;
          // right:50px
        }
      }
      a{
        font-size:13px;
        margin-top:9px;
        position: absolute;
        right:9%
      }
  }
  }

//   ::v-deep .ant-form-item{
//     margin-bottom:0
//   }

  .fileAdd {
    width: 80px;
    height: 25px;
    opacity: 0;
  }
  .uploadVideo {
    // border: #dddddd 1px solid;
    // border-radius: 5px;
    // width: 120px;
    // text-align: center;
    height: 32px;
    cursor: pointer;
    position: relative;
  }

  ::v-deep .ant-modal-body{
    min-height:300px;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
  .pbaiqoian{
    font-size:15px
  }

  // .aCol ::v-deep .ant-col-8{
  //   width:35%
  // }
  // .aCol ::v-deep .ant-col-16{
  //   width:60%
  // }
</style>