import {get, post, put, del, getExel, postById, putById, getById, postWorld, postExel} from './base'

// /api/admin/getYCompanyPayConfig

export function getYCompanyPayConfig () {
  return getById('/api/admin/getYCompanyPayConfig')
}
export function setCompanyConfig (data) {
  return postById('/api/admin/setCompanyConfig', data)
}
export function updCompanyPayConfig (data) {
    return postById('/api/admin/common/updCompanyPayConfig', data)
  }
